import { Link as GatsbyLink } from 'gatsby';

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
export const Link = ({
  children,
  href,
  target,
  activeClassName,
  partiallyActive,
  ...restProps
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(href);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal && target !== '_blank') {
    return (
      <GatsbyLink
        to={href}
        target={target}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...restProps}
      >
        {children}
      </GatsbyLink>
    );
  }

  // if no link, use a button
  if (!href || href === '#') {
    return <button {...restProps}>{children}</button>;
  }

  // external links
  return (
    <a href={href} target={target || '_blank'} {...restProps}>
      {children}
    </a>
  );
};
