import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import logo from '../assets/images/tpg.svg';
import { getMenuItems } from '../utils';
import { LinkedIn } from './Icons';
import { Link } from './Link';

export const Footer = () => {
  const data = useStaticQuery(FOOTER_QUERY);

  const { footerMenuItems, legalMenuItems } = useMemo(() => {
    const menuItems = getMenuItems(data.mainMenu.menuItems);
    const menuItemsCta = getMenuItems(data.ctaMenu.menuItems);
    return {
      footerMenuItems: [...menuItems, { path: '#', children: menuItemsCta }],
      legalMenuItems: getMenuItems(data.legalMenu.menuItems),
    };
  }, [data]);

  return (
    <footer className="site-footer">
      <div className="container mt-3 items-end justify-between sm:flex lg:mb-24">
        <img src={logo} className="footer-logo" alt="TPG" />
      </div>
      <div className="site-footer--menu">
        <div className="wrapper">
          {footerMenuItems?.map((menuItem, index) => (
            <div key={index} className="site-footer--list">
              {menuItem.label &&
                (!menuItem.path || menuItem.path === '#' ? (
                  <small className="site-footer--list-item-parent w-fit">
                    {menuItem.label}
                  </small>
                ) : (
                  <Link
                    href={menuItem.path}
                    className="site-footer--list-item-parent link-default w-fit"
                  >
                    {menuItem.label}
                  </Link>
                ))}
              {(menuItem.children.length > 1
                ? menuItem.children
                : menuItem.children[0]?.children
              )?.map((childMenuItem, index) => (
                <div key={index} className="site-footer--list-container">
                  <Link
                    href={childMenuItem.path}
                    className="site-footer--list-item link-default hover:text-white"
                  >
                    {childMenuItem.label}
                  </Link>
                </div>
              ))}
            </div>
          ))}
          <div className="site-footer--list">
            <div className="site-footer--list-container">
              <Link
                href="https://www.linkedin.com/company/tpg-capital/"
                taget="_blank"
                className="site-footer--list-item link-default hover:text-white flex overflow-hidden"
              >
                <LinkedIn />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="site-footer--last-line">
        <p className="copy">
          © {new Date().getFullYear()} Tarrant Capital IP, LLC, All Rights
          Reserved. TPG, the Half Star logo, and related marks and logos are
          service marks or registered marks owned by Tarrant Capital IP, LLC.
        </p>
        <div className="left-menu">
          {legalMenuItems?.map((menuItem) => {
            if (menuItem.path.indexOf('wp-content/uploads') > -1) {
              return (
                <a
                  key={menuItem.id}
                  href={menuItem.url}
                  target={menuItem.target}
                  className="left-menu--link link-default hover:!text-white"
                >
                  {menuItem.label}
                </a>
              );
            }

            return (
              <Link
                key={menuItem.id}
                href={menuItem.path}
                target={menuItem.target}
                className="left-menu--link link-default hover:!text-white"
              >
                {menuItem.label}
              </Link>
            );
          })}
        </div>
      </div>
    </footer>
  );
};

const FOOTER_QUERY = graphql`
  query FOOTER_QUERY {
    mainMenu: wpMenu(name: { eq: "Main Menu" }) {
      id
      menuItems {
        nodes {
          id: databaseId
          parentId: parentDatabaseId
          label
          path
          target
        }
      }
    }
    ctaMenu: wpMenu(name: { eq: "Small Menu" }) {
      id
      menuItems {
        nodes {
          id: databaseId
          parentId: parentDatabaseId
          label
          path
          target
        }
      }
    }
    legalMenu: wpMenu(name: { eq: "Legal Menu" }) {
      id
      menuItems {
        nodes {
          id: databaseId
          parentId: parentDatabaseId
          label
          path
          target
          url
        }
      }
    }
  }
`;
