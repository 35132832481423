import clsx from 'clsx';
import { useState } from 'react';
import { Arrow, BulletPoint, CircleArrow, LargeOpenIcon } from './Icons';
import { Link } from './Link';

export const Button = ({
  className,
  spanClassName,
  bulletClassName = 'stroke-theme',
  arrowClassName = 'stroke-current',
  children,
  ...restProps
}) => {
  let textColor = false;

  // if className contains text-color, use that for circle's stroke
  if (className?.includes('text-')) {
    bulletClassName = bulletClassName?.replace(
      'stroke-theme',
      `stroke-current fill-current`,
    );
  } else {
    textColor = 'text-black';
  }

  return (
    <Link
      className={clsx([
        'btn btn--primary group relative flex items-start',
        textColor,
        className,
      ])}
      {...restProps}
    >
      <BulletPoint className={bulletClassName} />
      <span
        className={clsx([
          spanClassName,
          'text block !font-normal !leading-24 transition-all duration-300 group-hover:translate-x-1',
        ])}
      >
        {children}
      </span>
      <Arrow className={arrowClassName} />
    </Link>
  );
};

export const ArrowButton = ({
  className,
  direction,
  circleClassName,
  arrowClassName,
  children,
  ...restProps
}) => {
  const [isHovered, setIsHovered] = useState(false);

  // if className contains text-color, use that for circle's stroke
  if (className?.includes('text-')) {
    circleClassName = circleClassName?.replace(
      /stroke-([a-z]+)/,
      `stroke-current`,
    );
  }

  return (
    <Link
      className={className}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...restProps}
    >
      {children}
      <CircleArrow
        direction={direction}
        isHovered={isHovered}
        circleClassName={
          circleClassName || clsx(['stroke-black', isHovered && 'fill-white'])
        }
        arrowClassName={arrowClassName}
      />
    </Link>
  );
};

export const HeroButton = ({ className, parentRef, ...restProps }) => {
  const [isHovered, setIsHovered] = useState(false);

  const onClickHandler = () => {
    const target = parentRef.current?.nextElementSibling;

    if (target) {
      const { top } = target.getBoundingClientRect();

      window.scrollTo({
        top: window.scrollY + top - 100,
        behavior: 'smooth',
      });
    }
  };

  return (
    <button
      className={className}
      onClick={onClickHandler}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClickHandler(e);
        }
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      {...restProps}
      aria-label="go to next section"
    >
      <CircleArrow
        circleClassName="stroke-theme"
        arrowClassName="stroke-white"
        isHovered={isHovered}
      />
    </button>
  );
};

export const LoadMoreButton = ({ hasMore, loadMore }) => {
  if (!hasMore) {
    return null;
  }

  return (
    <button
      className="group col-span-full flex cursor-pointer flex-col items-center !gap-2 gap-y-4 place-self-center"
      onClick={loadMore}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          loadMore(e);
        }
      }}
    >
      <LargeOpenIcon className="opacity-20 group-hover:rotate-90 group-hover:opacity-100" />
      <small className="block origin-top -translate-y-1 scale-95 !scale-y-50 transform text-mbase text-black opacity-0 transition-all duration-100 group-hover:translate-y-0 group-hover:scale-100 group-hover:!scale-y-100 group-hover:opacity-100 md:text-base">
        Load More
      </small>
    </button>
  );
};
