/** @type {import('tailwindcss').Config} */
module.exports = {
  // mode: 'jit',
  content: [
    './src/**/*.{js,jsx,ts,tsx}',
    './public/**/*.json',
    './safelist.txt',
  ],
  safelist: [
    {
      pattern:
        /text-(black|white|cobalt|gray|rust|olive|teal|navy|plum|rise|ag)/,
    },
    {
      pattern:
        /bg-(black|white|cobalt|gray|rust|olive|teal|navy|plum|overlay|rise|ag)/,
    },
    {
      pattern:
        /stroke-(black|white|cobalt|gray|rust|olive|teal|navy|plum|overlay|rise|ag)/,
    },
    {
      pattern:
        /fill-(black|white|cobalt|gray|rust|olive|teal|navy|plum|overlay|rise|ag)/,
    },
    {
      pattern: /text-(left|center|right)/,
    },
    {
      pattern: /(m|p)(y|t|b)-(0|10|16|24|32)/,
      variants: ['md', 'lg', 'xl'],
    },
  ],
  theme: {
    container: {
      center: true,
      padding: '24px',
    },
    //Breakpoints
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1296px',
    },
    boxShadow: {
      nav: '0px 9px 9px rgba(0, 0, 0, 0.05);',
      card: ' 0px 8px 48px rgba(0, 0, 0, 0.05);',
      DEFAULT: '0px 4px 24px rgba(0, 0, 0, 0.15);',
      none: 'none',
    },
    fontFamily: {
      tpg: ['MaisonNeue', 'sans-serif'],
      book: ['MaisonNeueBook', 'sans-serif'],
    },
    fontWeight: {
      light: 300,
      normal: 400,
      medium: 500,
    },
    //letter spacing
    letterSpacing: {
      tightest: '-1px',
      tight: '-0.5px',
      0: '0',
      10: '.1px',
      20: '.2px',
      25: '.25px',
      50: '.50px',
      250: '2.5px',
    },
    //colors
    colors: {
      black: '#1E1E1E',
      white: '#fff',
      cobalt: '#0055FF',
      gray: {
        1: '#F4F4F4',
        2: '#E7E7E7',
        3: '#A0A0A0',
      },
      rust: {
        1: '#80420D',
        2: '#A65611',
      },
      olive: {
        1: '#4D5E34',
        2: '#6C8549',
      },
      teal: {
        1: '#005862',
        2: '#007987',
      },
      navy: {
        1: '#043867',
        2: '#0060BF',
      },
      plum: {
        1: '#452854',
        2: '#733F8C',
      },
      'rise-blue': {
        1: '#00548B',
        2: '#288DC1',
        '1-50': '#00548B80',
      },
      'rise-green': {
        1: '#00837E',
        2: '#00AD68',
      },
      slate: {
        1: '#2B3138',
        2: '#4E5565',
      },
      'ag-gold': {
        1: '#b5954f',
        2: '#b5954f',
      },

      theme: 'var(--page-color,theme(colors.cobalt))',
      primary: 'var(--page-color-primary,theme(colors.cobalt))',
      current: 'currentColor',
      transparent: 'transparent',
      overlay: '#00091A',
    },
    //font size
    fontSize: {
      // headings
      hero: withLetterSpacing(160, 176, '0.02em'),
      h1: withLetterSpacing(84, 104),
      h2: withLetterSpacing(64, 80),
      h3: withLetterSpacing(48, 60),
      h4: withLetterSpacing(36, 48),
      h5: withLetterSpacing(24, 32),
      mhero: withLetterSpacing(60, 68, '0.02em'),
      mh1: withLetterSpacing(44, 56),
      mh2: withLetterSpacing(38, 50),
      mh3: withLetterSpacing(32, 44),
      mh4: withLetterSpacing(26, 36),
      mh5: withLetterSpacing(20, 30),
      // body
      lg: withLetterSpacing(20, 30),
      base: withLetterSpacing(16, 24),
      sm: withLetterSpacing(12, 20),
      mlg: withLetterSpacing(18, 26),
      mbase: withLetterSpacing(15, 22),
      msm: withLetterSpacing(11, 18),
      // ui
      label: withLetterSpacing(12, 20, '0.15em'),
      stats: withLetterSpacing(80, 96),
      mlabel: withLetterSpacing(11, 18, '0.15em'),
      mstats: withLetterSpacing(60, 64),
      // flag
      flag: withLetterSpacing(16, 24, '0.03em'),
      // rise stats
      'stats-label': withLetterSpacing(64, 80, 0),
    },
    //line height
    lineHeight: {
      96: '96px',
      76: '76px',
      56: '56px',
      48: '48px',
      40: '40px',
      38: '38px',
      32: '32px',
      28: '28px',
      26: '26px',
      24: '24px',
      22: '22px',
      20: '20px',
      18: '18px',
      16: '16px',
    },
    borderRadius: {
      none: '0',
      10: '10px',
      20: '20px',
      30: '30px',
      40: '40px',
      50: '50px',
    },
    extend: {
      backgroundSize: {
        '100%': '100%',
      },
      transitionProperty: {
        height: 'height',
      },
      transform: ['hover', 'group-hover'],
      opacity: { 15: 0.15 },
    },
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
  ],
};

function rem(sz) {
  return `${sz / 16}rem`;
}
function withLetterSpacing(sz, lh, ltr) {
  return [
    rem(sz),
    {
      lineHeight: lh ? rem(lh) : 1.5,
      letterSpacing: ltr || '-0.02em',
    },
  ];
}
