export const filterItems = (list, search = '', filters) => {
  let filteredList = search
    ? list.filter(
        (item) => item.title.toLowerCase().indexOf(search.toLowerCase()) > -1,
      )
    : [...list];

  // for each type of filter in filters, filter filteredList
  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      filteredList = filteredList.filter(
        (item) => +item[key]?.some((item) => +item.id === +value),
      );
    }
  });

  return filteredList;
};
