/**
 * Layout component used by gatsby-plugin-layout
 */

import clsx from 'clsx';
import { useEffect } from 'react';
import { Footer } from './Footer';
import { GDPR } from './GDPR';
import { Header } from './Header';

const isServer = typeof window === 'undefined';
const sal = !isServer ? require('sal.js') : null;

const selectors = [
  '.wp-block-cover__inner-container > *',
  '.wp-block-columns:not(.is-style-stat-card) > .wp-block-column > *',
  '.wp-block-columns.is-style-stat-card',
  '.wp-block-group > .wp-block-group > *',
  '.wp-block-gallery > *',
  '.wp-block-media-text > *',
  '.wp-block-grid-area',
  '.tpg-row > *',
  '.post .content > *',
  // '.wp-block-query > *', // hardcoded
  // '.portfolio-item', // hardcoded
  // '.team-member', // hardcoded
  // '.news-item', // hardcoded
].join(', ');

const colors = {
  teal: ['#005862', '#007987'],
  blue: ['#043867', '#0060BF'],
  green: ['#4D5E34', '#6C8549'],
  purple: ['#452854', '#733F8C'],
  yellow: ['#80420D', '#A65611'],
  rise: ['#00548B', '#288DC1'],
  hub: ['#00548B', '#0055FF'],
  ag: ['#b5954f', '#b5954f'],
  default: ['#0055FF', '#0055FF'],
};

export default function Layout({ data, pageContext, children, location }) {
  useEffect(() => {
    document.querySelectorAll(selectors).forEach((el) => {
      if (el.classList.contains('wp-block-gallery')) return;

      // if el is taller than window height, don't animate
      if (el.getBoundingClientRect().height >= window.innerHeight) {
        el.setAttribute('data-sal', '');
        return;
      }

      el.setAttribute('data-sal', 'slide-up');
      el.setAttribute('data-sal-delay', '150');
      el.setAttribute('data-sal-easing', 'ease-out-sine');
      el.setAttribute('data-sal-duration', '600');
    });

    // init sal
    const salInstance = sal();

    const updateSal = () => {
      setTimeout(() => salInstance.update());
    };

    // reinit sal on load more
    window.addEventListener('loadMore', updateSal);

    locateHref();
    anchorScroll();

    return () => {
      window.removeEventListener('loadMore', updateSal);
    };
  }, [location]);

  // check if page has a hero block
  const { hasHero, isPost } = pageContext;
  const pageColor = data?.page?.postFields?.pageColor;
  // use page color if exists, or default cobalt

  const styles = colors[pageColor]
    ? {
        '--page-color-primary': colors[pageColor][0],
        '--page-color': colors[pageColor][1],
      }
    : isPost
    ? {
        '--page-color-primary': colors.hub[0],
        '--page-color': colors.hub[1],
      }
    : {
        '--page-color-primary': colors.default[0],
        '--page-color': colors.default[1],
      };

  return (
    <>
      <Header active={!hasHero} />
      <main
        id="content"
        className={clsx([
          'blocks',
          'text-black',
          pageColor === 'rise' && 'theme-rise',
          !hasHero && 'pt-[94px] lg:pt-[109px]',
          isPost && 'post',
        ])}
        tabIndex="-1"
        style={styles}
      >
        {children}
      </main>
      <Footer />
      <GDPR />
    </>
  );
}

//Smooth scroll on anchor with offset
const anchorScroll = () => {
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    const href = anchor.getAttribute('href');

    if (href.length > 2) {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();
        const element = document.querySelector(href);

        if (element) {
          const yOffset = -70;
          const y =
            element.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({
            top: y,
            behavior: 'smooth',
          });
        }
      });
    }
  });
};

// Smooth scroll on load with offset
const locateHref = () => {
  const currUrl = window.location.href;
  if (currUrl.search('#') !== -1) {
    if (currUrl.split('#')[1] && currUrl.split('#')[1].length > 1) {
      window.scrollTo(0, 0);
      const yOffset = -70;
      let target = currUrl.split('#')[1];
      target = document.getElementById(target);
      if (target) {
        const y = target.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({
          top: y,
          behavior: 'smooth',
        });
      }
    }
  }
};
