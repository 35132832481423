import { Transition } from '@headlessui/react';
import { useLocation } from '@reach/router';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { Fragment, useEffect, useRef, useState } from 'react';
import { getMenuItems } from '../utils';
import { Link } from './Link';

import logo from '../assets/images/tpg.svg';

export const Header = ({ active }) => {
  const location = useLocation();

  const data = useStaticQuery(HEADER_QUERY);
  const menuItems = getMenuItems(data.mainMenu.menuItems);
  const menuItemsCta = getMenuItems(data.ctaMenu.menuItems);

  // Sticky menu
  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    const handleSticky = () => {
      const y = window.scrollY;
      setSticky(y > 150);
    };

    window.addEventListener('scroll', handleSticky);
    return () => {
      window.removeEventListener('scroll', handleSticky);
    };
  }, []);

  const logoRef = useRef();

  // For mobile menu
  const [showMenu, setShowMenu] = useState({ open: true, item: null });
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileSubMenu, setShowMobileSubMenu] = useState({ open: -1 });

  useEffect(() => {
    // close menu on route change
    setShowMenu({ open: false });
  }, [location]);

  // actions
  const handleMouseEnter = (item) => () => {
    setShowMenu({
      open: true,
      item,
    });
  };
  const handleMouseLeave = () => {
    setShowMenu({ open: false });
  };

  const menuList = [
    ...menuItems,
    'break',
    ...menuItemsCta.map((i) => ({ ...i, isCta: true })),
  ];
  const navRefs = menuList.reduce((refs, item) => {
    if (item === 'break') return refs;
    refs[item.id] = useRef();
    return refs;
  }, {});

  const hasOpenMenu = showMenu.open && showMenu.item;
  const isActive = sticky || hasOpenMenu || showMobileMenu || active;

  return (
    <>
      <a
        href="#content"
        className="focus:ring-offset-gray-800 sr-only !absolute z-20 bg-white focus:not-sr-only focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
        onClick={() => {
          document.getElementById('content')?.focus();
        }}
      >
        Skip to main content
      </a>

      <div
        className="fixed left-0 top-0 z-[999] w-full"
        onMouseLeave={handleMouseLeave}
      >
        <header
          className={clsx([
            'site-header transition-all duration-300',
            (sticky || hasOpenMenu || showMobileMenu || active) &&
              'active group',
          ])}
        >
          <div className="wrapper !max-w-[98%] xl:!max-w-[1296px]">
            <div className="logo-wrapper">
              <Link
                href="/"
                className="flex"
                onClick={() => {
                  setShowMenu({ open: false });
                }}
              >
                <span className="sr-only">TPG</span>
                <img
                  className="logo"
                  src={logo}
                  ref={logoRef}
                  role="presentation"
                  alt=""
                />
              </Link>
            </div>

            <ul className="navbar">
              {menuList.map((item) =>
                item === 'break' ? (
                  <li key={'break'}>
                    <div
                      className={clsx([
                        'h-[34px] w-[1px]',
                        isActive ? 'bg-black bg-opacity-20' : 'bg-white/30',
                      ])}
                    />
                  </li>
                ) : (
                  <li
                    key={item.id}
                    ref={navRefs[item.id]}
                    onMouseEnter={handleMouseEnter(item)}
                    onClick={handleMouseEnter(item)}
                    className={clsx([
                      'navbar-item',
                      item === showMenu.item && 'navbar-item--hover',
                      item.isCta &&
                        (isActive ? 'text-black/70' : 'text-white/80'),
                    ])}
                  >
                    <Link href={item.path}>{item.label}</Link>
                  </li>
                ),
              )}
            </ul>
            <div className="hamburger-container lg:hidden">
              <button
                onClick={() => {
                  setShowMobileMenu(!showMobileMenu);
                }}
              >
                <HamburgerIcon isOpen={showMobileMenu} />
              </button>
            </div>
          </div>
        </header>
        <Transition
          show={showMenu.open}
          className="relative top-[108px] z-[11]"
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <DropdownMenu
            item={showMenu.item}
            navRefs={navRefs}
            logoRef={logoRef}
            menuHandler={setShowMenu}
          />
        </Transition>
        <Transition
          show={showMobileMenu}
          className="fixed left-0 top-0 z-[99] h-screen w-full origin-top overflow-auto bg-white pt-[92px]"
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <ul className="navbar border-t border-black border-opacity-10 py-6">
            {menuList.map((item, index) => {
              if (item === 'break') return;

              const hasChildren = !!item.children?.length;

              return (
                <li key={item.id} className="navbar-item">
                  <Link
                    href={!hasChildren && item.path}
                    className="block px-9 py-2 text-[20px] leading-[30px] text-black"
                    onClick={(e) => {
                      if (hasChildren) {
                        e.preventDefault();
                        return setShowMobileSubMenu({
                          open: showMobileSubMenu?.open === index ? -1 : index,
                        });
                      }
                      setShowMobileMenu(false);
                    }}
                  >
                    {item.label}
                  </Link>

                  {hasChildren && (
                    <Transition
                      show={showMobileSubMenu?.open === index}
                      className="my-2 flex w-full flex-col bg-gray-1 px-9 py-8"
                    >
                      {item.children.map((childItem) => {
                        const hasChildren = !!item.children?.length;

                        return (
                          <Fragment key={childItem.id}>
                            <Link
                              href={!hasChildren && childItem.path}
                              className={clsx([
                                'block text-[15px] leading-22 text-black',
                                hasChildren
                                  ? 'mt-10 font-medium first:mt-0'
                                  : 'text-opacity-70',
                              ])}
                              onClick={() =>
                                !hasChildren && setShowMobileMenu(false)
                              }
                            >
                              {childItem.label}
                            </Link>

                            {hasChildren && (
                              <Transition
                                show={true}
                                className="mt-4 flex w-full flex-col space-y-4"
                              >
                                {childItem.children.map((grandchildItem) => (
                                  <Link
                                    key={grandchildItem.id}
                                    href={grandchildItem.path}
                                    className="block text-[15px] leading-22 text-black text-opacity-70"
                                    onClick={() => setShowMobileMenu(false)}
                                  >
                                    {grandchildItem.label}
                                  </Link>
                                ))}
                              </Transition>
                            )}
                          </Fragment>
                        );
                      })}
                    </Transition>
                  )}
                </li>
              );
            })}
          </ul>
        </Transition>
      </div>
    </>
  );
};

const DropdownMenu = ({ item, navRefs, menuHandler, logoRef }) => {
  const dropdownRef = useRef();
  const positionRef = navRefs[item?.id] || null;
  const [moveX, setMoveX] = useState(0);

  useEffect(() => {
    if (!dropdownRef.current && !positionRef?.current) return;
    if (!item || !item.children?.length) return;

    const coords = positionRef.current.getBoundingClientRect() || {};
    const el = dropdownRef.current.getBoundingClientRect();
    const lRef = logoRef.current?.getBoundingClientRect() || {};
    const pCenter = coords.left + coords.width / 2;
    const eCenter = el.width / 2;
    let pos = pCenter - eCenter;
    if (item.label === 'Platforms') pos = lRef.left - 96;
    setMoveX(pos < 0 ? 0 : pos);
  }, [item, dropdownRef]);

  if (!item || !item.children?.length) return null;

  const isBigMenu = item?.label === 'Platforms';

  return (
    <div>
      <ul
        ref={dropdownRef}
        className={clsx([
          'header-dropdown',
          isBigMenu
            ? 'is-big grid-cols-4 gap-6 !py-8 lg:grid lg:w-screen lg:px-14 xl:!w-[1440px] xl:gap-x-12 xl:!px-24'
            : 'flex flex-col justify-start',
        ])}
        style={{
          transform: `translateX(${moveX}px)`,
        }}
        onMouseLeave={() => {
          menuHandler({ open: false });
        }}
      >
        {item.children.map((childItem) => {
          return (
            <DropdownMenuItem
              key={childItem.id}
              item={childItem}
              parent={true}
              isBigMenu={isBigMenu}
            />
          );
        })}
      </ul>
    </div>
  );
};

const DropdownMenuItem = ({ item, parent, isBigMenu }) => {
  return (
    <li
      className={clsx([parent && 'header-dropdown-item', isBigMenu && '!px-0'])}
    >
      {parent ? (
        <small className="group relative border-white py-2 text-base !font-medium text-black">
          {item.label}
        </small>
      ) : (
        <Link
          href={item.path}
          className="group relative border-white py-2 !font-book text-base font-normal text-black/70 hover:text-black"
        >
          {item.label}

          <span className="absolute bottom-0 left-0 w-0 border-b border-black transition-all duration-300 group-hover:w-full" />
        </Link>
      )}
      {!!item.children?.length && (
        <ul>
          {item.children.map((childItem) => (
            <DropdownMenuItem key={childItem.id} item={childItem} />
          ))}
        </ul>
      )}
    </li>
  );
};

const HamburgerIcon = ({ isOpen }) => {
  return (
    <svg
      className={clsx(['burger stroke-black', isOpen && 'is-open'])}
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      strokeWidth={2}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 6H20" className="top" />
      <path d="M0 14H20" className="bot" />
    </svg>
  );
};

const HEADER_QUERY = graphql`
  query HEADER_QUERY {
    mainMenu: wpMenu(name: { eq: "Main Menu" }) {
      id
      menuItems {
        nodes {
          id: databaseId
          parentId: parentDatabaseId
          label
          path
          target
        }
      }
    }
    ctaMenu: wpMenu(name: { eq: "Small Menu" }) {
      id
      menuItems {
        nodes {
          id: databaseId
          parentId: parentDatabaseId
          label
          path
          target
        }
      }
    }
  }
`;
