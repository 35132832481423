export const createPlaceholderCanvas = ({
  name,
  width = 200,
  height = 200,
}) => {
  if (typeof document === 'undefined') return { sourceUrl: '', srcSet: '' };
  const element = document.createElement('canvas');
  const context = element.getContext('2d');

  element.width = width;
  element.height = height;

  // Fill in the background
  context.fillStyle = 'white';
  context.fillRect(0, 0, element.width, element.height);

  // Place the text
  context.font = 'bold 22px MaisonNeue';
  context.fillStyle = '#1E1E1E';
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.fillText(`${name}`, element.width / 2, element.height / 2);

  const url = element.toDataURL();
  return {
    sourceUrl: url,
    srcSet: '',
  };
};
