// map wordpress classes to tailwind
export const mapClasses = (classes = '') => {
  const classList = classes
    .split(' ')
    .filter(
      (className) =>
        className !== 'has-text-color' &&
        className !== 'has-background' &&
        className !== 'wp-element-button',
    );

  return classList
    .map((className) => {
      if (className.includes('has-text-align')) {
        return className.replace('has-text-align-', 'text-');
      }

      if (className.includes('background-color')) {
        return className.replace(/has-(.*)-background-color/, 'bg-$1');
      }

      if (className.includes('color')) {
        return className.replace(/has-(.*)-color/gi, 'text-$1');
      }

      if (className.includes('wp-block-button__link')) {
        return 'wp-block-button';
      }

      return className;

      // if (className.includes('font-size')) {
      //   return className.replace(/has-(.*)-font-size/gi, '$1');
      // }
    })
    .join(' ');
};
