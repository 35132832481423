import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useState } from 'react';
import { html } from '../utils';
import { Button } from './Buttons';
import { Chevron, CloseIcon } from './Icons';

export const GDPR = () => {
  const data = useStaticQuery(GDPR_QUERY);
  const content = data.acfOptions.gdpr.acf_options_GDPR;

  const [showGDPR, setShowGDPR] = useState(false);

  const [showNecessary, setShowNecessary] = useState(false);
  const [showNonNecessary, setShowNonNecessary] = useState(false);

  const [reviewCookieState, setReviewCookie] = useState('0');
  const [enableCookieState, setEnableCookie] = useState('0');

  const handleTracking = () => {
    if (localStorage.getItem('enableCookie') === '1') {
      window['ga-disable-UA-33468502-1'] = false;
    } else {
      window['ga-disable-UA-33468502-1'] = true;
    }
  };

  useEffect(() => {
    const handleCookiePrefsClick = (e) => {
      if (e.target.classList.contains('cookiePrefs')) {
        setShowGDPR(true);
      }
    };
    document.body.addEventListener('click', handleCookiePrefsClick);

    const reviewCookie = localStorage.getItem('tpg-reviewCookie');
    const enableCookie = localStorage.getItem('enableCookie');

    // Store the review cookie status
    if (reviewCookie === undefined || reviewCookie === null) {
      localStorage.setItem('tpg-reviewCookie', '0');
      setReviewCookie('0');
    } else if (reviewCookie === '1') {
      setReviewCookie('1');
    }

    // Store the cookie status
    if (!enableCookie || enableCookie === '0') {
      localStorage.setItem('enableCookie', '0');
    } else if (enableCookie === '1') {
      localStorage.setItem('enableCookie', '1');
    }

    // Check cookie enable
    handleTracking();

    return () => {
      document.body.removeEventListener('click', handleCookiePrefsClick);
    };
  }, []);

  const updateLocalStorage = (e) => {
    e.stopPropagation();
    let enableCookie = localStorage.getItem('enableCookie');
    localStorage.setItem('enableCookie', enableCookie === '0' ? '1' : '0');
    setEnableCookie(enableCookie === '0' ? '1' : '0');
    handleTracking();
  };

  const handleAutoAccept = (e) => {
    e.preventDefault();
    setShowGDPR(false);
    localStorage.setItem('tpg-reviewCookie', '1');
    setReviewCookie('1');
  };

  const handleAccept = (e) => {
    e.preventDefault();
    localStorage.setItem('tpg-reviewCookie', '1');
    setReviewCookie('1');

    localStorage.setItem('enableCookie', '1');
    setEnableCookie('1');
    handleTracking();
  };

  const handleReject = (e) => {
    e.preventDefault();
    localStorage.setItem('tpg-reviewCookie', '1');
    setReviewCookie('1');

    localStorage.setItem('enableCookie', '0');
    setEnableCookie('0');
  };

  const handleSettings = (e) => {
    e.preventDefault();
    setShowGDPR(true);
  };

  const toggleNecessary = (e) => {
    e.preventDefault();
    setShowNecessary(!showNecessary);
  };

  const toggleNonNecessary = (e) => {
    setShowNonNecessary(!showNonNecessary);
  };

  return (
    <>
      {reviewCookieState === '0' && (
        <div className="fixed bottom-0 left-0 z-[11] h-auto w-full bg-black bg-center py-6 lg:py-4">
          <div className="container my-1">
            <div className="items-center justify-between lg:flex">
              <div className="lead mb-6 text-white lg:mb-0">
                {html(
                  content.gdprFixedFooter?.gdprDescription ||
                    `This site uses cookies so that we can analyze how it is used and improve the site and your experience. <br/>
                    You can change your preferences at any time.`,
                )}
              </div>
              <div className="grid grid-cols-2 gap-4 lg:block">
                <Button
                  className="text-white"
                  arrowClassName="stroke-cobalt"
                  onClick={handleAccept}
                >
                  Accept
                </Button>
                <Button
                  className="text-white"
                  arrowClassName="stroke-cobalt"
                  onClick={handleReject}
                >
                  Reject
                </Button>
                <Button
                  className="text-white"
                  arrowClassName="stroke-cobalt"
                  onClick={handleSettings}
                >
                  Cookie Settings
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Transition
        show={showGDPR}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-200 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="fixed left-0 top-0 z-[99] h-full w-full"
      >
        <div
          className="absolute left-0 top-0 h-full min-h-screen w-full bg-black bg-opacity-50"
          onClick={(e) => {
            e.preventDefault();
            setShowGDPR(false);
          }}
        />
        <div className="container absolute left-1/2 top-1/2 max-h-[95%] -translate-x-1/2 -translate-y-1/2 transform overflow-auto bg-white p-6 md:max-w-2xl md:p-8">
          <div className="flex justify-end">
            <button
              onClick={handleAutoAccept}
              className="flex items-center font-medium"
            >
              <CloseIcon className="stroke-black opacity-20 hover:opacity-100" />
            </button>
          </div>
          <p className="mb-4 font-medium text-black">Privacy Policy</p>
          <div className="lead opacity-70">
            {html(content.gdprModal?.privacyPolicy)}
          </div>

          <div
            className="mt-10 flex cursor-pointer justify-between rounded-30 bg-gray-2 px-5 py-4"
            onClick={toggleNecessary}
          >
            <div className="lead flex items-center">
              <Chevron
                className="opacity-20"
                direction={showNecessary && 'up'}
              />
              Necessary
            </div>
            <div className="text-label uppercase">Always Enabled</div>
          </div>
          <Transition show={showNecessary}>
            <div className="lead px-5 py-3 opacity-70 empty:hidden">
              {html(content.gdprModal?.necessaryCookiesDescription)}
            </div>
          </Transition>
          <div
            className="mt-6 flex cursor-pointer justify-between rounded-30 bg-gray-2 px-5 py-4"
            onClick={toggleNonNecessary}
          >
            <div className="lead flex flex-1 items-center">
              <Chevron
                className="opacity-20"
                direction={showNonNecessary && 'up'}
              />
              Non-Necessary
            </div>
            <div className="text-label uppercase" onClick={updateLocalStorage}>
              {enableCookieState === '1' ? 'Enabled' : 'Disabled'}
              <div className="relative z-[1] ml-4 mr-2 inline-block w-10 select-none align-middle transition-all duration-200 ease-in">
                <div
                  className={clsx([
                    'toggle-checkbox absolute m-0.5 block h-5 w-5 cursor-pointer appearance-none rounded-20 border-none bg-white transition-all',
                    enableCookieState === '1' ? 'right-0' : 'right-auto',
                  ])}
                />
                <label
                  className={clsx([
                    'toggle-label block h-6 cursor-pointer overflow-hidden rounded-20 transition-all',
                    enableCookieState === '1' ? ' bg-cobalt' : 'bg-gray-3',
                  ])}
                />
              </div>
            </div>
          </div>
          <Transition show={showNonNecessary}>
            <div className="lead px-5 py-3 opacity-70 empty:hidden">
              {html(content.gdprModal?.nonNecessaryCookiesDescription)}
            </div>
          </Transition>
        </div>
      </Transition>
    </>
  );
};

const GDPR_QUERY = graphql`
  query GDPR_QUERY {
    acfOptions: wp {
      gdpr: acfOptionsGdpr {
        acf_options_GDPR {
          gdprFixedFooter {
            gdprDescription
          }
          gdprModal {
            necessaryCookiesDescription
            nonNecessaryCookiesDescription
            privacyPolicy
          }
        }
      }
    }
  }
`;
