export const formatDateString = (_date) => {
  const date = new Date(_date);
  const month = date.toLocaleDateString('default', { month: 'short' });
  const day = date.toLocaleDateString('default', { day: '2-digit' });
  const year = date.getFullYear();

  return `${month}.${day}.${year}`;
};

export const formatRiseDateString = (_date) => {
  const date = new Date(_date);
  const month = date.toLocaleDateString('default', { month: 'long' });
  const day = date.toLocaleDateString('default', { day: 'numeric' });
  const year = date.getFullYear();

  return `${month} ${day} ${year}`;
};

export const formatShortDateString = (_date) => {
  const date = new Date(_date);
  const month = date.toLocaleDateString('default', { month: 'numeric' });
  const day = date.toLocaleDateString('default', { day: '2-digit' });
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};
